<template>
  <div class="free-ad">
    <div class="row margin-0 justify-content-center">
      <div class="col-12 pad-0">
        <h3 class="margin-bottom-15" v-if="payload.id">UPDATE YOUR AD</h3>
        <h3 class="margin-bottom-15" v-else>POST YOUR AD</h3>
        <sn-observer ref="adForm">
          <div class="ad-block row margin-0">
            <div
              class="col-md-7 col-12 pad-0"
              style="border-right: 1px solid #ddd"
            >
              <div class="sub-form">
                <h5 class="sub-heads">AD CATEGORY</h5>
                <div class="row margin-0">
                  <div class="col-md-6 col-12 pad-left-0">
                    <sn-input
                      type="select"
                      :rules="['required']"
                      :show-label="true"
                      select-label="Select Category"
                      label="Category"
                      :options="
                        cats ? cats.map((item) => [item.title, item.id]) : []
                      "
                      v-model="payload.category"
                      :value="payload.category"
                      :error="error.category"
                    />
                  </div>
                  <div
                    class="col-md-6 col-12 pad-left-0"
                    v-if="payload.category"
                  >
                    <sn-input
                      type="select"
                      :rules="['required']"
                      :show-label="true"
                      select-label="Select Sub Category"
                      label="Sub Category"
                      :options="
                        subCats[payload.category].map((item) => [
                          item.title,
                          item.id,
                        ])
                      "
                      v-model="payload.sub_category"
                      :value="payload.sub_category"
                      :error="error.sub_category"
                    />
                  </div>
                </div>
              </div>
              <div class="sub-form">
                <h5 class="sub-heads">BASIC DETAILS</h5>
                <div class="row margin-0">
                  <div class="col-12 pad-left-0">
                    <sn-input
                      type="text"
                      :rules="['required']"
                      label="Ad Title"
                      name="Ad Title"
                      :value="payload.title"
                      :oerror="error.title"
                      v-model="payload.title"
                      :showLabel="true"
                    />
                  </div>
                  <div class="col-12 pad-left-0">
                    <sn-input
                      style="height: 100px"
                      type="textarea"
                      :rules="['required']"
                      label="Description"
                      name="Description"
                      :value="payload.description"
                      :oerror="error.description"
                      v-model="payload.description"
                      :showLabel="true"
                    />
                  </div>
                </div>
              </div>
              <div class="sub-form">
                <h5 class="sub-heads">SET PRICE</h5>
                <div class="row margin-0">
                  <div class="col-md-6 col-12 pad-left-0">
                    <sn-input
                      type="number"
                      :rules="['required']"
                      :show-label="true"
                      :placeholder="
                        payload.data && payload.data.hasOwnProperty('income_to')
                          ? 'Income From'
                          : 'Selling Price'
                      "
                      :label="
                        payload.data && payload.data.hasOwnProperty('income_to')
                          ? 'Income From'
                          : 'Selling Price'
                      "
                      :name="
                        payload.data && payload.data.hasOwnProperty('income_to')
                          ? 'Income From'
                          : 'Selling Price'
                      "
                      v-model="payload.price"
                      :value="payload.price"
                      :error="error.price"
                    />
                    <!-- <div class="form-group">
                                        <label v-if="payload.data && payload.data.hasOwnProperty('income_to')" for="">Income From<span class="req">*</span></label>
                                        <label v-else for="">Selling Price<span class="req">*</span></label>
                                        <input type="number" class="form-control" v-model="payload.price" placeholder="price of your Ad">
                                        <span class="error" v-if="error && error.price">{{error.price}}</span>
                                    </div> -->
                  </div>
                  <div
                    v-if="
                      payload.data && payload.data.hasOwnProperty('income_to')
                    "
                    class="col-md-6 col-12 pad-left-0"
                  >
                    <sn-input
                      type="number"
                      :rules="['required']"
                      :show-label="true"
                      placeholder="Income to"
                      label="Income to"
                      name="Income to"
                      v-model="payload.data.income_to"
                      :value="payload.data.income_to"
                      :error="error.income_to"
                    />
                    <!-- <div class="form-group">
                                        <label for="">Income To<span class="req">*</span></label>
                                        <input type="text" class="form-control" v-model="payload.data.income_to" placeholder="Income to">
                                        <span class="error" v-if="error && error.data && error.data.income_to">{{error.data.income_to}}</span>
                                    </div> -->
                  </div>
                  <div
                    v-if="
                      payload.data && payload.data.hasOwnProperty('income_type')
                    "
                    class="col-md-6 col-12 pad-left-0"
                  >
                    <div class="form-group">
                      <label for=""
                        >Income Period<span class="req">*</span></label
                      >
                      <select
                        name=""
                        v-model="payload.data.income_type"
                        id=""
                        class="form-control"
                      >
                        <option value="Daily">Daily</option>
                        <option value="Weekly">Weekly</option>
                        <option value="Monthly">Monthly</option>
                        <option value="Yearly">Yearly</option>
                      </select>
                      <span
                        class="error"
                        v-if="error && error.data && error.data.income_type"
                        >{{ error.data.income_type }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="sub-form" v-if="payload.sub_category && payload.data">
                <h5 class="sub-heads">SOME MORE DETAILS</h5>
                <div class="row margin-0">
                  <div
                    v-if="
                      payload.data &&
                      payload.data.hasOwnProperty('building_type')
                    "
                    class="col-md-6 col-12 pad-left-0"
                  >
                    <div class="form-group">
                      <label for=""
                        >Building Type<span class="req">*</span></label
                      >
                      <select
                        name=""
                        v-model="payload.data.building_type"
                        id=""
                        class="form-control"
                      >
                        <option :value="null" disabled selected>Select</option>
                        <option value="Apartment">Apartment</option>
                        <option value="Villa">Villa</option>
                        <option value="House">House</option>
                      </select>
                      <span
                        class="error"
                        v-if="error && error.data && error.data.building_type"
                        >{{ error.data.building_type }}</span
                      >
                    </div>
                  </div>
                  <div
                    v-if="
                      payload.data && payload.data.hasOwnProperty('bedrooms')
                    "
                    class="col-md-6 col-12 pad-left-0"
                  >
                    <div class="form-group">
                      <label for="">Bedrooms<span class="req">*</span></label>
                      <input
                        type="number"
                        class="form-control"
                        v-model="payload.data.bedrooms"
                        placeholder="No of Bedrooms"
                      />
                      <span
                        class="error"
                        v-if="error && error.data && error.data.bedrooms"
                        >{{ error.data.bedrooms }}</span
                      >
                    </div>
                  </div>
                  <div
                    v-if="
                      payload.data && payload.data.hasOwnProperty('bathrooms')
                    "
                    class="col-md-6 col-12 pad-left-0"
                  >
                    <div class="form-group">
                      <label for="">Bathrooms<span class="req">*</span></label>
                      <input
                        type="number"
                        class="form-control"
                        v-model="payload.data.bathrooms"
                        placeholder="No of bathrooms"
                      />
                      <span
                        class="error"
                        v-if="error && error.data && error.data.bathrooms"
                        >{{ error.data.bathrooms }}</span
                      >
                    </div>
                  </div>
                  <div
                    v-if="payload.data && payload.data.hasOwnProperty('area')"
                    class="col-md-6 col-12 pad-left-0"
                  >
                    <div class="form-group">
                      <label for=""
                        >Building/Plot area (sqmtrs)<span class="req"
                          >*</span
                        ></label
                      >
                      <input
                        type="number"
                        class="form-control"
                        v-model="payload.data.area"
                        placeholder="No of sqmtrs"
                      />
                      <span
                        class="error"
                        v-if="error && error.data && error.data.area"
                        >{{ error.data.area }}</span
                      >
                    </div>
                  </div>
                  <div
                    v-if="
                      payload.data &&
                      payload.data.hasOwnProperty('building_status')
                    "
                    class="col-md-6 col-12 pad-left-0"
                  >
                    <div class="form-group">
                      <label for=""
                        >Building Status<span class="req">*</span></label
                      >
                      <select
                        name=""
                        v-model="payload.data.building_status"
                        id=""
                        class="form-control"
                      >
                        <option :value="null" disabled selected>Select</option>
                        <option value="Under Construction">
                          Under Construction
                        </option>
                        <option value="Ready to occupy">Ready to occupy</option>
                      </select>
                      <span
                        class="error"
                        v-if="error && error.data && error.data.building_status"
                        >{{ error.data.building_status }}</span
                      >
                    </div>
                  </div>
                  <div
                    v-if="payload.data && payload.data.hasOwnProperty('brand')"
                    class="col-md-6 col-12 pad-left-0"
                  >
                    <div class="form-group">
                      <label for="">Brand<span class="req">*</span></label>
                      <select
                        name=""
                        v-model="payload.data.brand"
                        id=""
                        class="form-control"
                      >
                        <option :value="null" disabled selected>Select</option>
                        <template
                          v-for="(item, index) in formDefinitions[
                            this.payload.sub_category
                          ].brand"
                        >
                          <option :key="index" :value="item">{{ item }}</option>
                        </template>
                      </select>
                      <span
                        class="error"
                        v-if="error && error.data && error.data.brand"
                        >{{ error.data.brand }}</span
                      >
                    </div>
                  </div>
                  <div
                    v-if="payload.data && payload.data.hasOwnProperty('year')"
                    class="col-md-6 col-12 pad-left-0"
                  >
                    <div class="form-group">
                      <label for="">Year<span class="req">*</span></label>
                      <select
                        name=""
                        v-model="payload.data.year"
                        id=""
                        class="form-control"
                      >
                        <option :value="null" disabled selected>Select</option>
                        <template v-for="(item, index) in 20">
                          <option :key="index" :value="2020 - item">
                            {{ 2020 - item }}
                          </option>
                        </template>
                      </select>
                      <span
                        class="error"
                        v-if="error && error.data && error.data.year"
                        >{{ error.data.year }}</span
                      >
                    </div>
                  </div>
                  <div
                    v-if="
                      payload.data && payload.data.hasOwnProperty('mileage')
                    "
                    class="col-md-6 col-12 pad-left-0"
                  >
                    <div class="form-group">
                      <label for="">Mileage<span class="req">*</span></label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="payload.data.mileage"
                        placeholder="Enter Kilometers"
                      />
                      <span
                        class="error"
                        v-if="error && error.data && error.data.mileage"
                        >{{ error.data.mileage }}</span
                      >
                    </div>
                  </div>
                  <div
                    v-if="payload.data && payload.data.hasOwnProperty('fuel')"
                    class="col-md-6 col-12 pad-left-0"
                  >
                    <div class="form-group">
                      <label for="">Fuel<span class="req">*</span></label>
                      <select
                        name=""
                        v-model="payload.data.fuel"
                        id=""
                        class="form-control"
                      >
                        <option :value="null" disabled selected>Select</option>
                        <option value="Petrol">Petrol</option>
                        <option value="Diesel">Diesel</option>
                        <option value="Electric">Electric</option>
                      </select>
                      <span
                        class="error"
                        v-if="error && error.data && error.data.fuel"
                        >{{ error.data.year }}</span
                      >
                    </div>
                  </div>
                  <div
                    v-if="
                      payload.data && payload.data.hasOwnProperty('transition')
                    "
                    class="col-md-6 col-12 pad-left-0"
                  >
                    <div class="form-group">
                      <label for="">transition<span class="req">*</span></label>
                      <select
                        name=""
                        v-model="payload.data.transition"
                        id=""
                        class="form-control"
                      >
                        <option :value="null" disabled selected>Select</option>
                        <option value="Manual">Manual</option>
                        <option value="Automatic">Automatic</option>
                      </select>
                      <span
                        class="error"
                        v-if="error && error.data && error.data.transition"
                        >{{ error.data.year }}</span
                      >
                    </div>
                  </div>
                  <div
                    v-if="payload.data && payload.data.hasOwnProperty('owner')"
                    class="col-md-6 col-12 pad-left-0"
                  >
                    <div class="form-group">
                      <label for="">Owner<span class="req">*</span></label>
                      <select
                        name=""
                        v-model="payload.data.owner"
                        id=""
                        class="form-control"
                      >
                        <option :value="null" disabled selected>Select</option>
                        <option value="1st Owner">1st Owner</option>
                        <option value="2nd Owner">2nd Owner</option>
                        <option value="3rd or More">3rd or More</option>
                      </select>
                      <span
                        class="error"
                        v-if="error && error.data && error.data.owner"
                        >{{ error.data.year }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="sub-form">
                <h5 class="sub-heads">LOCATION</h5>
                <div class="row margin-0">
                  <div class="col-md-6 col-12 pad-left-0">
                    <sn-input
                      type="select"
                      :rules="['required']"
                      :show-label="true"
                      select-label="Select State"
                      label="State"
                      name="State"
                      :options="statesList"
                      v-model="payload.state"
                      :value="payload.state"
                      :error="error.state"
                    />
                    <!-- <div class="form-group">
                                        <label for="">State<span class="req">*</span></label>
                                        <select name="" v-model="payload.state" @change="payload.city=payload.area=null" id="" class="form-control">
                                            <option :value="null" disabled selected>Select</option>
                                            <template v-for="(item, index) in states">
                                                <option :key="index" :value="index">{{index}}</option>
                                            </template>
                                        </select>
                                        <span class="error" v-if="error && error.state">{{error.state}}</span>
                                    </div> -->
                  </div>
                  <div class="col-md-6 col-12 pad-left-0" v-if="payload.state">
                    <sn-input
                      type="select"
                      :rules="['required']"
                      :show-label="true"
                      select-label="Select City"
                      label="City"
                      name="City"
                      :options="states[payload.state]"
                      v-model="payload.city"
                      :value="payload.city"
                      :error="error.city"
                    />
                    <!-- <div class="form-group">
                                        <label for="">City<span class="req">*</span></label>
                                        <select name="" v-model="payload.city" id="" class="form-control">
                                            <option :value="null" disabled selected>Select</option>
                                            <template v-for="(item, index) in states[payload.state]">
                                                <option :key="index" :value="item">{{item}}</option>
                                            </template>
                                        </select>
                                        <span class="error" v-if="error && error.city">{{error.city}}</span>
                                    </div> -->
                  </div>
                  <div class="col-md-6 col-12 pad-left-0" v-if="payload.city">
                    <sn-input
                      type="text"
                      :rules="['required']"
                      label="Area"
                      name="Area"
                      :value="payload.area"
                      :oerror="error.area"
                      v-model="payload.area"
                      :showLabel="true"
                    />
                    <!-- <div class="form-group">
                                        <label for="">Area<span class="req">*</span></label>
                                        <input type="text" class="form-control" v-model="payload.area" placeholder="Area or Near by area">
                                        <span class="error" v-if="error && error.area">{{error.area}}</span>
                                    </div> -->
                  </div>
                </div>
              </div>
              <div class="sub-form">
                <h5 class="sub-heads">WAY TO REACH YOU</h5>
                <div class="row margin-0">
                  <div
                    class="col-md-12 col-12 pad-left-0"
                    v-if="profile && payload.contact"
                  >
                    <sn-input
                      style="margin: 0"
                      type="checkbox"
                      :label="`Call (+91 ${profile.mobile})`"
                      v-model="payload.contact.mobile"
                      :value="payload.contact.mobile"
                      :showLabel="true"
                    />
                    <sn-input
                      style="margin: 0"
                      type="checkbox"
                      :label="`Chat`"
                      v-model="payload.contact.chat"
                      :value="payload.contact.chat"
                      :showLabel="true"
                    />
                    <div class="form-group" v-if="false">
                      <input
                        type="checkbox"
                        v-model="payload.contact.mobile"
                        placeholder="price of your Ad"
                      />
                      <span class="margin-right-20"> </span>
                      <!-- <input type="checkbox" v-model="payload.contact.wp" placeholder="price of your Ad">
                                        <span class="margin-right-20"> WhatsApp (+91 {{profile.mobile}})</span> -->
                      <input
                        type="checkbox"
                        v-model="payload.contact.chat"
                        disabled
                        placeholder="price of your Ad"
                      />
                      <span class="margin-right-20"> Chat</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-md-5 col-12 pad-0"
              style="border-bottom: 1px solid #ddd"
            >
              <!-- <div id="canvasBlock"></div> -->
              <img
                src="/icon.png"
                alt=""
                style="display: none; opacity: 0.2"
                id="logowatermark"
              />
              <div class="sub-form" style="border: none">
                <h5 class="sub-heads">UPLOAD PICTURES</h5>
                <div class="pmv-img" v-if="payload.thumbnail">
                  <img
                    :src="payload.thumbnail"
                    style="max-height: 40vh"
                    class="img-fluid"
                    alt=""
                  />
                </div>
                <div>
                  <ul class="thumb-list">
                    <template v-for="(item, index) in payload.images">
                      <li
                        @click="payload.thumbnail = item"
                        :key="index"
                        :class="{ active: payload.thumbnail === item }"
                      >
                        <img :src="item" alt="" />
                        <span class="fa-times-close" @click="removeImg(index)">
                          <fal-icon name="times" />
                        </span>
                      </li>
                    </template>
                    <li @click="$refs.imgfile.click()">
                      <span style="font-size: 0.7em"
                        ><fal-icon name="camera" /><br />Add Photo</span
                      >
                    </li>
                  </ul>
                </div>
                <template v-if="upload_status && upload_status !== {}">
                  <div class="row margin-0">
                    <template v-for="(itm, idx) in upload_status">
                      <div
                        :key="idx"
                        class="col-12 pad-10 margin-top-10"
                        style="border: 1px solid #ddd"
                      >
                        <span>{{ itm.filename }}</span>
                        <div>
                          <span style="font-size: 0.6em">{{ itm.status }}</span>
                          <span
                            style="font-size: 0.6em"
                            :class="{
                              'text-success': itm.progress === 100,
                              'text-warning':
                                itm.progress > 1 && itm.progress < 100,
                            }"
                            class="f-right"
                            >{{ itm.progress }}%</span
                          >
                        </div>
                        <div class="progress margin-top-0" style="height: 5px">
                          <div
                            :class="{
                              'bg-success': itm.progress === 100,
                              'bg-warning':
                                itm.progress > 1 && itm.progress < 100,
                            }"
                            class="progress-bar"
                            :style="{ width: itm.progress + '%' }"
                          ></div>
                        </div>
                      </div>
                    </template>
                  </div>
                </template>
              </div>
            </div>
            <div class="col-12 pad-15">
              <input
                style="display: none"
                @change="uploadFile()"
                id="imgfile"
                type="file"
                accept="image/*"
                ref="imgfile"
              />
              <button
                class="btn btn-success margin-right-10"
                v-if="payload.id"
                @click="updateAd()"
              >
                Update
              </button>
              <button
                class="btn btn-success margin-right-10"
                v-else
                @click="createAd()"
              >
                Post now
              </button>
              <button
                class="btn btn-light text-danger"
                @click="$router.push('/my-ads/')"
              >
                Cancel
              </button>
            </div>
          </div>
        </sn-observer>
      </div>
    </div>
    <!-- saving, edit, get pop -->
    <div class="pop" v-if="saving">
      <div class="message-board">
        <h5>Please wait....</h5>
        <template v-if="saving === 'create'">
          <img src="/img/priority.png" class="save-img" alt="" />
          <p class="text-center">Saving and adding your ad in priority list.</p>
        </template>
        <template v-else-if="saving === 'get'">
          <img src="/img/get.webp" class="save-img" alt="" />
          <p class="text-center">Retreving your Ad and creating form.</p>
        </template>
        <template v-else-if="saving === 'update'">
          <img src="/img/priority.png" class="save-img" alt="" />
          <p class="text-center">
            Updating and adding your ad in priority list.
          </p>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: ["id"],
  data() {
    return {
      // profile: JSON.parse(localStorage.getItem('user_details')) || null,
      payload: {
        category: null,
        sub_category: null,
        title: null,
        description: null,
        state: null,
        city: null,
        area: null,
        price: 0,
        thumbnail: null,
        images: [],
        ends_on: null,
        data: null,
        contact: {
          whatsapp: false,
          chat: true,
          mobile: true,
        },
      },
      error: {},
      cities: null,
      states: null,
      selcted_city: null,
      formData: null,
      formDefinitions: null,
      saving: null,
      upload_status: {},
    };
  },
  computed: {
    statesList: function () {
      return Object.keys(this.states);
    },
  },
  watch: {
    "payload.category": function () {
      this.payload.data = {};
    },
    "payload.sub_category": function () {
      this.payload.data =
        this.$constants.form_data[this.payload.sub_category] || null;
    },
  },
  created() {
    this.formDefinitions = this.$constants.form_definitions;
    this.states = this.$constants.states;
    this.cities = this.$constants.cities;
    if (this.id) {
      this.getProduct();
    }
  },
  methods: {
    createAd() {
      const that = this;
      that.$refs.adForm.validate().then(
        (res) => {
          if (res) {
            that.saving = "create";
            that.$cs.myproduct.create({ data: that.payload }).then(
              (res) => {
                that.saving = null;
                that.$router.push("/my-ads/");
              },
              (err) => {
                that.saving = null;
                console.error(err);
              }
            );
          }
        },
        (err) => {}
      );
    },
    updateAd() {
      const that = this;
      that.$refs.adForm.validate().then(
        (res) => {
          if (res) {
            that.saving = "update";
            that.$cs.myproduct
              .update({
                resource: `${that.$cs.myproduct.api}${that.id}/`,
                data: that.payload,
              })
              .then(
                (res) => {
                  that.saving = null;
                  that.$router.push("/my-ads/");
                },
                (err) => {
                  that.saving = null;
                  console.error(err);
                }
              );
          }
        },
        (err) => {}
      );
    },
    getProduct() {
      const that = this;
      that.saving = "get";
      that.$cs.myproduct
        .list({
          resource: `${that.$cs.myproduct.api}${that.id}/`,
        })
        .then(
          (res) => {
            that.payload = res.data;
            if (!that.payload.contact || !that.payload.contact.wp) {
              that.payload.contact = {
                whatsapp: true,
                chat: true,
                mobile: true,
              };
            }
            that.saving = null;
          },
          (err) => {
            that.saving = null;
            console.error(err);
          }
        );
    },
    removeImg(index) {
      this.payload.images.splice(index, 1);
      if (index === 0) {
        this.payload.thumbnail = this.payload.images[0];
      }
    },
    resetPayload() {
      this.payload = {
        category: null,
        sub_category: null,
        title: null,
        description: null,
        state: null,
        city: null,
        area: null,
        price: 0,
        thumbnail: null,
        images: [],
        ends_on: null,
        data: null,
        contact: {
          whatsapp: true,
          chat: true,
          mobile: true,
        },
      };
      this.error = null;
      this.upload_status = {};
    },
    async validateData() {
      const that = this;
      that.error = {};
      var proceed = await this.$refs.adForm.validate();
      // if (that.payload.category === null || that.payload.category === "") {
      //     this.error.category = "Select the product category";
      //     proceed = false;
      // }
      // if (that.payload.sub_category === null || that.payload.sub_category === "") {
      //     this.error.sub_category = "Select the product sub category";
      //     proceed = false;
      // }
      // if (that.payload.title === null || that.payload.title === "") {
      //     this.error.title = "Title field is required";
      //     proceed = false;
      // }
      // if (that.payload.description === null || that.payload.description === "") {
      //     this.error.description = "Description field should not be null";
      //     proceed = false;
      // }
      // if (that.payload.state === null || that.payload.state === "") {
      //     this.error.state = "State should not be null";
      //     proceed = false;
      // }
      // if (that.payload.city === null || that.payload.city === "") {
      //     this.error.city = "City should not be null";
      //     proceed = false;
      // }
      // if (that.payload.area === null || that.payload.area === "") {
      //     this.error.area = "Area should not be null";
      //     proceed = false;
      // }
      // if (that.payload.price === null || that.payload.price === "" || that.payload.price <= 0) {
      //     this.error.price = "Price should be greater than 1 rupee";
      //     proceed = false;
      // }
      return proceed;
    },
    uploadFile() {
      const that = this;
      // var file1 = new File();
      var file1 = document.getElementById("imgfile").files[0];
      const fnm = new Date().getTime();
      if (file1 !== undefined) {
        that.upload_status[fnm] = {
          filename: file1.name,
          status: "Validating Image",
          progress: 0,
        };
        // convert image to webp
        new Promise(function (resolve, reject) {
          let rawImage = new Image();
          rawImage.addEventListener("load", function () {
            resolve(rawImage);
          });
          rawImage.src = URL.createObjectURL(file1);
        })
          .then(function (rawImage) {
            // Convert image to webp ObjectURL via a canvas blob
            return new Promise(function (resolve, reject) {
              let canvas = document.createElement("canvas");
              let ctx = canvas.getContext("2d");
              canvas.width = rawImage.width;
              canvas.height = rawImage.height;
              // document.querySelector("#canvasBlock").appendChild(canvas);
              let logoWatermark = document.getElementById("logowatermark");
              ctx.drawImage(rawImage, 0, 0);
              let dX = canvas.width - canvas.height * 0.22;
              let dY = canvas.height * 0.8;
              let dH = canvas.height * 0.2;
              ctx.drawImage(logoWatermark, dX, dY, dH, dH);
              canvas.toBlob(function (blob) {
                var file245 = new File([blob], "dot.webp", {
                  type: "image/webp",
                });
                console.log(file245);
                // resolve(URL.createObjectURL(blob));
                resolve(file245);
              }, "image/webp");
            });
          })
          .then(function (file2) {
            that.upload_status[fnm].status = "Read to Upload";
            that.$cs.profile
              .getSignedLinkForUpload({
                fileName: Math.random().toString(36).substr(2, 9) + ".webp",
                fileType: "image/webp",
                access: true,
                type: "ads",
              })
              .then((response) => {
                const signedData = response.data;
                const options = {
                  headers: {
                    "Content-Type": "image/webp",
                  },
                  onUploadProgress(progressEvent) {
                    (that.upload_status[fnm].status = "Uploading..."),
                      (that.upload_status[fnm].progress = `${Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total
                      )}`);
                  },
                }; // options
                axios
                  .put(signedData.signed_url, file2, options)
                  .then(() => {
                    that.payload.images.push(signedData.url);
                    that.payload.thumbnail = that.payload.images[0];
                    that.upload_status[fnm].status = "Done";
                    that.upload_status[fnm].progress = 100;
                  })
                  .catch(() => {
                    that.upload_status[fnm].status = "Failed";
                  });
              })
              .catch(() => {
                that.upload_status[fnm].status = "Failed";
              });
            // var file2 = fetch(imageURL,{mode:"cors"})
            // .then(res => res.blob())
            // .then(blob => {
            //     const file = new File([blob], 'dot.webp', {type:'image/webp'});
            // });
          });
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
.ad-block
    border-radius 4px
.form-group
    margin-bottom 0.5rem
    label
        font-size 0.8em
        color #444
    .form-control
        border-width 2px
        padding .575rem
        font-size 1em
        line-height 1em
        &:focus
            border-color #034764
            outline none
            box-shadow none
.pmv-img
    height 60vh
    display flex
    text-align center
    align-items center
    justify-content center
.pop
  position fixed
  top 0
  right 0
  left 0
  bottom 0
  z-index 10000
  background-color rgba(0,0,0,0.6)
  padding 25px 15px
  text-align center
  display flex
  justify-content center
  align-items center
  .message-board
    background-color #fff
    padding 20px
    display inline-block
    border-radius 3px
    width 50%
    text-align center
    .save-img
        margin 10px
        max-width 100px
    p
        margin-top 2vh
        color #444
@media (max-width: 767px)
    .pop
        .message-board
            width 75%
.free-ad
    padding 14px 0px
    .ad-block
        background-color #ffffff
        border 1px solid #ddd
.thumb-list
    list-style none
    margin 0px
    padding 0px
    width 100%
    max-width 100%
    overflow-x scroll
    li
        width 5vw
        height 5vw
        max-height 5vw
        border 2px solid #ddd
        margin 5px
        float left
        cursor pointer
        display flex
        text-align center
        align-items center
        justify-content center
        position relative
        .fa-times-close
            position absolute
            background-color orangered
            color white
            height 20px
            width 20px
            font-size 12px
            border-radius 50%
            cursor pointer
            display flex
            align-items center
            justify-content center
            top -7px
            right -7px
        &.active
            border-color red
        img
            max-width 100%
            max-height 15vw
.sub-form
    padding 14px 0px 14px 14px
    border-bottom 1px solid #ddd
    .sub-heads
        font-weight bold
        margin-bottom 15px
        // font-size 1em
@media (max-width: 767px)
    .free-ad
        padding 2vh 0px
    .thumb-list
        li
            width 15vw
            min-height 15vw
</style>
